<template>
  <Defaultlayout :userprofile="userprofile">
    <section class="basket">
      <div class="container" v-if="loading==false">
        <div class="order-box" v-if="getcartdata.status == '1'  && loading==false">
          <div class="profile-id">
            <div class="media">
              <div class="ordered-profile mr-3">
                <router-link :to="'/shopdetail?storeid='+getcartdata.data.store.store_id">
                  <img
                    :src="getcartdata.data.store.store_logo"
                    alt="..."
                    v-if="getcartdata.data.store.store_logo!=''"
                  >
                  <avatar
                    :fullname="getcartdata.data.store.store_name.split(' ')[0]"
                    :size="80"
                    :style="'border-radius:10px !important;'"
                    v-if="getcartdata.data.store.store_logo==''"
                  ></avatar>
                </router-link>
              </div>
              <div class="media-body">
                <div class="row form-row">
                  <div class="col-sm-7 order-description">
                    <router-link :to="'/shopdetail?storeid='+getcartdata.data.store.store_id" style="color:#000;">
                      <h5>{{getcartdata.data.store.store_name}}</h5>
                    </router-link>
                    <!-- <a href="javascript:void(0)" class="d-block" @click="getStoreByCategory(getcartdata.data.store)" :title="getcartdata.data.store.category_name">
                    <h6>{{getcartdata.data.store.category_name}}</h6>
                    </a> -->
                    <a class="location" href="#" v-if="getcartdata.data.store.city_name">
                      <img src="../../assets/images/location-icon-gray.png" alt="location-icon.png">
                      <!-- <span v-if="getcartdata.data.store.area_name"> {{getcartdata.data.store.area_name}}</span> -->
                      <span v-if="getcartdata.data.store.city_name"> {{getcartdata.data.store.city_name}}</span>
                      <span v-if="getcartdata.data.store.state_name">, {{getcartdata.data.store.state_name}}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="your-basket">

          <div class="d-sm-flex justify-content-sm-between flex-wrap" v-if="getcartdata.status == '1'">
            <h2 class="section-heading d-inline-block">Your Basket ({{ totalCntProductQnty }} items)</h2>
            <div class="your-basket-right d-flex justify-content-between">
              <div class="select-status active" v-if="getcartdata.data.order_settings.delivery == 1 || getcartdata.data.order_settings.pickup == 1">
                <select class="form-control" v-model="delivery_pickup" @change="showDeliveryChanges()">
                <option value="1" v-if="getcartdata.data.order_settings.delivery == 1">Delivery</option>
                <option value="2" v-if="getcartdata.data.order_settings.pickup == 1 && iseatry == 1">Take Away</option>
                <option value="2" v-if="getcartdata.data.order_settings.pickup == 1 && iseatry == 0">Pickup</option>
                </select>
              </div>
              <div class="add-more-btn ml-3">
                <router-link :to="'shopdetail?storeid='+getcartdata.data.store.store_id" :style="colorObj" class="btn button-style hoverEffect" style="padding: 7px;">Add More</router-link>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="basket-order col-lg-12 mt-4 mt-lg-0" v-if="getcartdata.status == '0'">
              <div class="table-responsive basket-list">
                <div class="empty-box text-center">
                  <div class="contents">
                    <div class="empty-image">
                      <img src="../../assets/images/svgs/empty-box.svg" alt="empty-box">
                    </div>
                  <h3>{{ getcartdata.message }}</h3>
                  </div>
                  <a href="/" :style="colorObj" class="btn button-style btn-large hoverEffect">Go to Home</a>
                </div>
              </div>
            </div>

            <div class="basket-order col-lg-8 mt-4 mt-lg-0" v-if="getcartdata.status == '1'">
              <div class="table-responsive basket-list">
                <p class="table-title d-md-none">Order Items</p>
                <table class="table table-borderless" border="0">
                  <thead>
                    <tr>
                      <th class="order-item-main">Order Items</th>
                      <th class="unit-price-main">Unit Price</th>
                      <th class="quantity-main">Quantity</th>
                      <th class="subtotal-main">Subtotal</th>
                      <th class="close-main"></th>
                    </tr>
                  </thead>
                  <tbody v-for="(cartdata, index) in getcartdata.data.items" v-bind:key="index">
                    <tr class="first-item" v-for="(product, ind) in getAddedCartItems(cartdata.item_variants)" v-bind:key="ind">
                      <td class="order-item">
                        <div class="d-flex">
                          <div class="ordered-profile mr-3">
                            <router-link :to="'/productdetail?itemid=' + cartdata.item_id">
                              <img :src="cartdata.item_image">
                            </router-link>
                          </div>
                          <div class="order-description d-flex flex-wrap align-items-center">
                            <h5 class="w-100 mb-2">
                              <router-link :to="'/productdetail?itemid=' + cartdata.item_id" class="text-black">
                                {{ cartdata.item_name }}
                              </router-link>
                            </h5>
                            <h6 class="w-100 mb-2 text-black">
                              <span v-if="parseFloat(product.unit_quantity) != '0'">
                                {{ Number( product.unit_quantity ).toFixed(1) }}
                              </span>
                              <span v-if="product.unit_name != null">
                                {{ product.unit_name }}
                              </span>
                              <span v-if="product.packaging_name != null">
                                {{ product.packaging_name }}
                              </span>

                            </h6>
                            <!-- <h6 class="w-100 mb-2 text-black" v-if="parseFloat(product.min_qty) > 0">
                              (Min {{ product.min_qty }} - Max {{ product.max_qty }})
                            </h6>
                             -->

                            <div class="text-left">
                              <span v-if="parseFloat(product.min_qty) > 0 && parseFloat(product.max_qty) > 0">
                                (Min {{ parseFloat(product.min_qty) }} - Max {{ parseFloat(product.max_qty) }})
                              </span>
                              <span v-if="parseFloat(product.min_qty) > 0 && parseFloat(product.max_qty) == 0">
                                (Min {{ parseFloat(product.min_qty) }})
                              </span>
                              <span v-if="parseFloat(product.min_qty) == 0 && parseFloat(product.max_qty) > 0">
                                (Max {{ parseFloat(product.max_qty) }})
                              </span>
                            </div>
                            <h6 class="w-100 mb-2 text-black" v-if="product.choices != null">
                              <span v-for="(choice,ind) in product.choices" v-bind:key="ind">
                                {{choice.choice_prices != null && choice.choice_prices.length > 0 ? choice.variant_choice_name:''}}:
                                <span v-for="(choice_price,ind1) in choice.choice_prices" v-bind:key="ind1">
                                  <span v-if="(choice_price.is_added == 1)">
                                    {{choice_price.variant_sub_choice_name}}<template v-if="ind1 + 1 < choice.choice_prices.length">, </template>
                                  </span>
                                </span>
                                <br>
                              </span>
                            </h6>
                            <h6 class="w-100 mb-2 text-black" v-if="product.addons != null">
                              <span v-for="(addon,ind) in product.addons" v-bind:key="ind">
                                {{addon.addon_prices != null && addon.addon_prices.length > 0 ? addon.variant_addon_name:''}}:
                                <span v-for="(addon_price,ind1) in addon.addon_prices" v-bind:key="ind1">
                                  <span v-if="(addon_price.is_added == 1)">
                                    {{addon_price.variant_sub_addon_name}}<template v-if="ind1 + 1 < addon.addon_prices.length">, </template>
                                  </span>
                                </span>
                                <br>
                              </span>
                            </h6>
                            <h6 class="w-100 mb-2 text-black" v-if="cartdata.combo_variants != null">
                              <span v-for="(combo,ind) in cartdata.combo_variants" v-bind:key="ind">
                                {{combo.combo_variant_name}}
                                <br>
                                <span class="ml-4" v-if="parseFloat(combo.unit_quantity) != '0' || combo.unit_name != null || combo.packaging_name != null">
                                  <span v-if="parseFloat(combo.unit_quantity) != '0'">
                                    {{ Number( combo.unit_quantity ).toFixed(1) }}
                                  </span>
                                  <span v-if="combo.unit_name != null">
                                    {{ combo.unit_name }}
                                  </span>
                                  <span v-if="combo.packaging_name != null">
                                    {{ combo.packaging_name }}
                                  </span>
                                  <br>
                                </span>
                                <h6 class="w-100 mb-2 text-black" v-if="combo.choices != null">
                                  <span v-for="(choice,ind) in filterComboChoices(combo.choices)" v-bind:key="ind">
                                    <span v-if="choice.variant_sub_choice_name!= null && choice.variant_sub_choice_name!= ''">
                                    <span v-if="(ind == 0)">{{choice.variant_choice_name}}: </span>
                                    {{choice.variant_sub_choice_name}}<template v-if="ind + 1 < filterComboChoices(combo.choices).length">, </template> 
                                  </span>
                                  </span>
                                </h6>
                                <h6 class="w-100 mb-2 text-black" v-if="combo.addons != null">
                                  <span v-for="(addon,ind) in filterComboAddons(combo.addons)" v-bind:key="ind">
                                    <span v-if="addon.variant_sub_addon_name!= null && addon.variant_sub_addon_name!= ''">
                                    <span v-if="(ind == 0)">{{addon.variant_addon_name}}: </span>
                                    {{addon.variant_sub_addon_name}}<template v-if="ind + 1 < filterComboAddons(combo.addons).length">, </template> 
                                  </span>
                                  </span>
                                </h6>
                              </span>
                              <br>
                            </h6>
                            <span v-if="cartdata.prescription == 1" class="prescription-required">
                                Prescription required
                            </span>
                          </div>
                          <div class="basket-right">
                            <h5 class="price text-center">
                              <span v-html="priceFormatter(getUnitPrice(product, cartdata.combo_variants))"></span>
                            </h5>
                            <div class="quantity d-flex justify-content-between">
                              <div class="number row mx-0 ">
                                <span class="minus" @click="removeQuantity(getcartdata.data.store.store_id, cartdata, product, ind, index, cartdata.cart_id)">-</span>
                                <input
                                type="text"
                                :name="'quantity' + product.item_variants_id + '_' + ind + '_' + index"
                                :id="'quantity' + product.item_variants_id + '_' + ind + '_' + index"
                                :value="parseInt(product.quantity)"
                                @keypress="onlyNumber"
                              >
                              <span class="plus" @click="addQuantity(getcartdata.data.store.store_id, cartdata, product, ind, index, cartdata.cart_id,product.cart_id)">+</span>
                              </div>
                            </div>
                            <div class="close-main">
                              <button
                                class="close"
                                @click="
                                  removeSelCart(
                                    cartdata.cart_id,
                                    product.item_variants_id,
                                    cartdata.item_id,
                                    getcartdata.data.store.store_id,
                                  )
                                "
                              >&times;</button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="unit-price valign-middle">
                        <span>
                          <h5 class="price">
                            <span v-html="priceFormatter(getUnitPrice(product, cartdata.combo_variants))"></span>
                          </h5>
                          <span class="delet-price" v-if="product.discounted_price!=product.item_price" v-html="priceFormatter(getUnitDiscountedPrice(product, cartdata.combo_variants))"></span>
                        </span>
                      </td>
                      <td class="valign-middle quantity-main">
                        <span>
                          <div class="quantity d-flex justify-content-between">
                            <div class="number row mx-0">
                              <span class="minus" @click="removeQuantity(getcartdata.data.store.store_id, cartdata, product, ind, index, cartdata.cart_id)">-</span>
                              <input
                                type="text"
                                :name="'quantity' + product.item_variants_id + '_' + ind + '_' + index"
                                :id="'quantity' + product.item_variants_id + '_' + ind + '_' + index"
                                :value="parseInt(product.quantity)"
                                @keypress="onlyNumber"
                              >
                              <span class="plus" @click="addQuantity(getcartdata.data.store.store_id, cartdata, product, ind, index, cartdata.cart_id,product.cart_id)">+</span>
                            </div>
                          </div>
                        </span>
                      </td>
                      <td class="valign-middle price-main">
                        <h5 class="price">
                          <span v-html="priceFormatter(calPriceByQty(getUnitPrice(product, cartdata.combo_variants), product.quantity))"></span>
                        </h5>
                          <span class="delet-price" v-if="product.discounted_price!=product.item_price" v-html="priceFormatter(calPriceByQty(getUnitDiscountedPrice(product, cartdata.combo_variants), product.quantity))"></span>
                      </td>
                      <td class="valign-middle close-main d-none d-md-table-cell">
                        <button
                          class="close"
                          @click="
                            removeSelCart(
                              cartdata.cart_id,
                              product.item_variants_id,
                              cartdata.item_id,
                              getcartdata.data.store.store_id,
                            )
                          "
                        >&times;</button>
                      </td>
          <div
            class="modal fade repeat-alert-modal"
            id="RepeatAlertModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="RepeatAlertModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="RepeatAlertModalLabel" v-if="repeat_addon_choice != null">
                      {{repeat_addon_choice.item_name}}
                      <p>Repeat last used choices/addons?</p>
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" v-if="repeat_addon_choice != null">
                  <h6>
                    <span v-for="(choice_addon, ind) in repeat_addon_choice.choice_addon_details" v-bind:key="ind">
                      <span>{{choice_addon}}</span><br>
                    </span>
                  </h6>
                  <h5><span v-html="priceFormatter(repeat_addon_choice.discounted_price)"></span></h5>
                </div>
                <div class="modal-footer">
                  <button type="button" :style="colorObj" class="btn repeat-btn hoverEffect" @click="repeatItemAddonChoices(getcartdata.data.store.store_id)">Repeat</button>
                  <button type="button" :style="colorObj" class="btn add-new-btn hoverEffect" @click="addNewItemAddonChoices()">Add New</button>
                </div>
              </div>
            </div>
          </div>
                      <div
                        class="modal fade repeat-alert-modal"
                        :id="'RepeatComboAlertModal'+ind+index"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="RepeatComboAlertModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="RepeatComboAlertModalLabel" v-if="repeat_combo != null">
                                  {{repeat_combo.item_name}}
                                  <p>Repeat the combo choices?</p>
                              </h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body" v-if="repeat_combo != null">
                              <h6>
                                <span v-for="(combo, ind1) in repeat_combo.combo_variants" v-bind:key="ind1">
                                  <span>{{combo.combo_variant_name}}</span><br>
                                  <span class="ml-4" v-if="parseFloat(combo.unit_quantity) != '0' || combo.unit_name != null || combo.packaging_name != null">
                                      <span v-if="parseFloat(combo.unit_quantity) != '0'">
                                        {{ Number( combo.unit_quantity ).toFixed(1) }}
                                      </span>
                                      <span v-if="combo.unit_name != null">
                                        {{ combo.unit_name }}
                                      </span>
                                      <span v-if="combo.packaging_name != null">
                                        {{ combo.packaging_name }}
                                      </span>
                                      <br>
                                  </span>
                                  <h6 class="w-100 mb-2 text-black" v-if="combo.choices != null">
                                    <span v-for="(choice,ind) in filterComboChoices(combo.choices)" v-bind:key="ind">
                                      <span v-if="(ind == 0)">{{choice.variant_choice_name}}: </span>
                                      {{choice.variant_sub_choice_name}}<template v-if="ind + 1 < filterComboChoices(combo.choices).length">, </template> 
                                    </span>
                                  </h6>
                                  <h6 class="w-100 mb-2 text-black" v-if="combo.addons != null">
                                    <span v-for="(addon,ind) in filterComboAddons(combo.addons)" v-bind:key="ind">
                                      <span v-if="(ind == 0)">{{addon.variant_addon_name}}: </span>
                                      {{addon.variant_sub_addon_name}}<template v-if="ind + 1 < filterComboAddons(combo.addons).length">, </template> 
                                    </span>
                                  </h6>
                                </span>
                              </h6>
                              <br>
                              <h5><span v-html="priceFormatter(repeat_combo.discounted_price)"></span></h5>
                            </div>
                            <div class="modal-footer">
                              <button type="button" :style="colorObj" class="btn repeat-btn hoverEffect" @click="repeatItemCombo(getcartdata.data.store.store_id)">Repeat</button>
                              <button type="button" :style="colorObj" class="btn add-new-btn hoverEffect" @click="addNewItemCombo(getcartdata.data.store.store_id)">Add New</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="basket-payment col-lg-4 mt-4 mt-lg-0">
              <ul class="payment-details-wrap list-unstyled" v-if="getcartdata.status == '1' && cart_details_loading==false">
                <li class="d-flex justify-content-between mt-3">
                  <h5 class="mb-0">Item Total</h5>
                  <p class="mb-0">
                    <span v-html="priceFormatter(subTotal)"></span>
                  </p>
                </li>
                <li
                  class="d-flex justify-content-between delivery-charge"
                  v-if="delivery_charges != null && show_delivery_charges == true"
                >
                  <h5 class="mb-0">
                    Delivery Charges
                    <span class="d-block mt-1 label" v-if="delivery_charges != null && delivery_charges.data.msg != null">
                      {{delivery_charges.data.msg}}
                    </span>
                  </h5>
                  <p class="mb-0">
                    <span v-html="priceFormatter(delivery_charges.data.delivery_charges)"></span>
                  </p>
                </li>
                  <li
                    class="d-flex justify-content-between delivery-charge"
                    v-if="this.getcartdata.total_tax  != '' && (parseFloat(this.getcartdata.data.total_tax)+parseFloat(this.getcartdata.data.packaging_cost)).toFixed(2)  != '0.00'"
                  >
                    <h5 class="mb-0">Taxes & Charges
                      <span class="tax-and-charges cart-section"><b><img src="../../assets/images/svgs/infoicon.svg"/></b>
                        <span class="title-span" v-html="(parseFloat(this.getcartdata.data.packaging_cost).toFixed(2) != 0.00 ?'Store Packaging Cost : '+this.currency_symbol+''+parseFloat(this.getcartdata.data.packaging_cost).toFixed(2)+ '<br/>':'')+(parseFloat(this.getcartdata.data.total_tax).toFixed(2) != 0.00 ?`${store_tax} : `+this.currency_symbol+''+parseFloat(this.getcartdata.data.total_tax).toFixed(2):'')">
                        </span>
                      </span>

                     </h5> 
                    <p class="mb-0">
                      <span v-html="priceFormatter((parseFloat(this.getcartdata.data.total_tax)+parseFloat(this.getcartdata.data.packaging_cost)).toFixed(2))"></span>
                    </p>
                  </li>
                <li class="discount d-flex justify-content-between">
                  <h5 class="mb-0">Grand Total</h5>
                  <p class="mb-0">
                    <span v-html="priceFormatter(totalAmount)"></span>
                  </p>
                </li>
                <li class="button row mx-0 justify-content-center">
                  <a href="javascript:void(0)" :style="colorObj" class="btn button-style check-out hoverEffect" @click="checkout">Checkout</a>
                  <a class="text-danger mt-2" href="javascript:void(0)" @click="removeCart">Clear Cart</a>
                </li>
              </ul>
              <div class="payment-details-wrap list-unstyled container" v-if="cart_details_loading==true">
                <p class="text-center">
                  <img src="../../assets/images/loading.gif" />
                </p>
              </div>
            </div>
          </div>
          <div class="add-more-items" v-if="getcartdata.status == '1'  && loading==false">
            <h2 class="section-heading mt-4">Add More Items</h2>
            <product-list :productlist="moreItems" v-if="getcatdetaildisp.status=='1'" :storeid="getcartdata.data.store.store_id" :limit="4" page="cart" :storestaus="getcartdata.data.store_status" :storeopen="isStoreOpen(getcartdata.data.business_hours) || getcartdata.data.order_settings.accept_after_close == 1"></product-list>
          </div>

        </div>
      </div>
      <div class="container" v-if="loading==true">
        <p class="text-center">
          <img src="../../assets/images/loading.gif" />
        </p>
      </div>
      <ComboOption pid="cart" @update="addToCartCombo"></ComboOption>
      <AvailableOption pid="cart" @update="addToCartAddonChoices"></AvailableOption>
    </section>
  </Defaultlayout>
</template>
<script>
import Avatar from "vue-avatar-component";
import Defaultlayout from "../../layouts/Defaultlayout";
import ProductList from "../product/ProductList";
import ComboOption from "../product/ComboOption";
import { bus } from "../../main";
import moment from "moment-timezone";
import helper from "../../store/helper";
import AvailableOption from "../product/AvailableOption";
import validationmessage from "../checkout/ValidationMessage";
export default {
  name: "Cart",
  components: {
    Defaultlayout,
    Avatar,
    "product-list": ProductList,
    ComboOption,
    AvailableOption,
  },
  mixins: [helper],
  data() {
    return {
      cart_details_loading: true,
      loading: true,
      currency_symbol: "₹",
      userprofile: null,
      getcartdata: null,
      getcatdetaildisp: [],
      sub_total: '',
      show_delivery_charges: true,
      delivery_pickup: 1,
      get_cart_detail: {
        user_id: "",
        cart_type: "",
        timezone: ""
      },
      remove_cart_item: {
        user_id: "",
        store_id: "",
        item_id: "",
        item_variants_id: "",
        cart_id: ""
      },
      store_detail: {
        store_id: "",
        category_id: "",
        user_id: "",
        page: "",
        out_of_stock: "",
      },
      remove_cart: {
        user_id: "",
        cart_type: ""
      },
      add_to_cart: {
        user_id: "",
        store_id: "",
        item_id: "",
        item_variants_id: "",
        quantity: "",
        cart_type: "",
        choices: "",
        addons: "",
        combo_items: "",
        add_new: 0,
      },
      remove_selected_item: false,
      category: {
        shop_category_id: "",
        shop_category_name: "",
        type: "",
      },
      delivery_charges_body: {
        user_id: "",
        store_id: "",
        order_amount: "",
        lat: "",
        long: "",
      },
      delivery_charges: null,
      delivery_charge_msg: "",
      used_combo: {
        item_id: '',
        item_name: '',
        brand_name: '',
        item_variants_id: '',
        item_price: '',
        choice_details: [],
        addon_details: [],
      },
      used_combo_data: {
        user_id: '',
        store_id: '',
        item_variants_id: '',
      },
      repeat_combo: null,
      repeat_addon_choice: null,
      item_qty: '',
      combo_data: {
        item: null,
        item_variant: null,
        index: '',
        index1: '',
        cart_id: '',
      },
            item_variant_data: {
        item: null,
        item_variant: null,
        index: '',
        index1: '',
        cart_id: '',
      },
      used_choices_addons: {
        user_id: '',
        store_id: '',
        item_variants_id: '',
        cart_id:'',
      },
      used_addon_choice: {
        item_id: '',
        item_name: '',
        brand_name: '',
        item_variants_id: '',
        item_price: '',
        choice_details: [],
        addon_details: [],
      },
      combo_item_data: {
         item_id: "",
         user_id: "",
       },
       combo_item_details: null,
       selectedComboItems: [],
       iseatry:0,
       default_address_object :null,
       show_price_same_font:1
    };
  },
  computed: {
    totalCntProductQnty() {
      var totalqnty = 0;
      if(this.getcartdata != null){
        if(this.getcartdata.status != 0){
          totalqnty = this.getcartdata.data.cart_details.quantity;
        }
      }
      return totalqnty;
    },
    moreItems() {      
      let more_items = [];
      this.getcatdetaildisp.data.forEach(d => {
        if(this.getcartdata.data.items.find(o => o.item_id === d.item_id) == undefined){
          if(d.avg_rating != null){
            d.avg_rating = Math.round(d.avg_rating * 10) / 10;
          }
          more_items.push(d);
        }
      });
      return more_items;
    },
    subTotal: function() {
      var sum = 0;
      if (this.getcartdata != null) {
        if (this.getcartdata.status != 0) {
          this.getcartdata.data.items.forEach(items => {

            var item_variants = items.item_variants.filter(
              function(elem) {
                if (elem.is_added == "1") return elem;
              }
            );

            item_variants.forEach(item_variant => {
              
              sum += parseFloat(item_variant.discounted_price) * parseFloat(item_variant.quantity);

              if(item_variant.choices != null) {
                item_variant.choices.forEach(choice => {
                  choice.choice_prices.forEach(choice_price => {
                      if(choice_price.is_added == 1){
                        sum += parseFloat(choice_price.price) * parseFloat(item_variant.quantity);
                      }
                  });
                });
              }

              if(item_variant.addons != null) {
                item_variant.addons.forEach(addon => {
                  addon.addon_prices.forEach(addon_price => {
                      if(addon_price.is_added == 1){
                        sum += parseFloat(addon_price.price) * parseFloat(item_variant.quantity);
                      }
                  });
                });
              }
            });

            if(items.combo_variants != null){
              items.combo_variants.forEach(combo_variant => {
                if(combo_variant.choices != null){
                  combo_variant.choices.forEach(choice => {
                    choice.choice_prices.forEach(choice_price => {
                      if(choice_price.is_added == 1){
                        sum += parseFloat(choice_price.price) * parseFloat(items.item_variants[0].quantity);
                      }
                    });
                  });
                }

                if(combo_variant.addons != null){
                  combo_variant.addons.forEach(addon => {
                    addon.addon_prices.forEach(addon_price => {
                      if(addon_price.is_added == 1){
                        sum += parseFloat(addon_price.price) * parseFloat(items.item_variants[0].quantity);
                      }
                    });
                  });
                }

              });
            }

          });
        }
      }
      return parseFloat(sum).toFixed(2);
    },
    totalAmount: function() {
      var newsum = this.subTotal;
      var totalsum = 0;
      if (this.delivery_charges != null && this.delivery_pickup == 1) {
        totalsum = parseFloat(newsum) + parseFloat(this.delivery_charges.data.delivery_charges);
      } else {
        totalsum = newsum;
      }
      if((parseFloat(this.getcartdata.data.total_tax)+parseFloat(this.getcartdata.data.packaging_cost)).toFixed(2)  != '0.00'){
        totalsum = parseFloat(totalsum) + parseFloat(this.getcartdata.data.total_tax)+parseFloat(this.getcartdata.data.packaging_cost);
      }
      return parseFloat(totalsum).toFixed(2);
    }
  },
  filters: {
    strippedCategoryName: function(value) {
      if (value.length > 15) {
        return value.slice(0, 12) + "...";
      }
      return value;
    },
  },
  updated(){
    window.$('<style>.comboOffer.modal .modal-dialog .nav-link.active .sr-no { background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
  },
  mounted() {
    this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹";
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
      window.location.href="/";
    }

    this.getUserAddress();
    this.getCart();

    localStorage.removeItem('delivery_pickup');
    localStorage.setItem('delivery_pickup', this.delivery_pickup);
    this.originalColor();
    document.body.classList.add('top-navbar');
    document.body.classList.remove('top-menu-add-padding');
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.show_price_same_font = JSON.parse(data).show_price_same_font;
      }
    });
  },
  methods: {
    space(str1,str2){
      return (str1 + ", " + str2)
    },
    spaceTwo(str1,str2){
      return (str1 + " " + str2)
    },
    ShowTitle(){
      window.$('.tax-and-charges').tooltip({ trigger: 'click' });
    },
    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) {
        $event.preventDefault();
      }
    },
    async  getUserAddress() {
      this.userprofile.user_id = this.getLoginUserId();

      this.$store.dispatch("customeraddress", this.userprofile).then(response => {
        var result = JSON.parse(JSON.stringify(response));
        if(result.status == 1){
          this.default_address_object = this.getDefaultAddress(result.data);
        }
      });
    },
    getDefaultAddress(user_address){
      return user_address.filter(
        function(elem) {
          if (elem.is_default == "1") return elem;
        }
      );
    },
    getDeliveryCharges() {
      this.cart_details_loading = true;
      this.delivery_charges_body.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.delivery_charges_body.store_id = this.getcartdata.data.store.store_id;
      this.delivery_charges_body.order_amount = this.subTotal;

      if(JSON.parse(localStorage.getItem("location_details")).customer_address_id != undefined) {
        this.delivery_charges_body.lat = JSON.parse(localStorage.getItem("location_details")).lat;
        this.delivery_charges_body.long = JSON.parse(localStorage.getItem("location_details")).lng;
      } else {
        this.delivery_charges_body.lat = "";
        this.delivery_charges_body.long = "";
      }
       
      this.$store
        .dispatch("getcustdeliverycharges", this.delivery_charges_body)
        .then(response => {
          var result  = JSON.parse(JSON.stringify(response));
          if (result.status != "0") {
            this.delivery_charges = JSON.parse(JSON.stringify(response));
          } else {
            this.delivery_charge_msg = result.message;    
            if (this.getcartdata.data.order_settings.delivery == 1 && this.delivery_pickup == 1 && this.default_address_object != undefined && this.default_address_object[0].address2 == '')
            {
              this.$toast.error(validationmessage, {timeout: 15000}); 
            }
            else
            {
              var add1 = this.default_address_object[0].address;
              var add2 = this.default_address_object[0].address2;
              var combineAdd = "(" + this.space(add1, add2) + ")";
              this.$toast.error(this.spaceTwo(this.delivery_charge_msg,combineAdd), {timeout:15000});
              window.$(document).ready(function(){
                var html = '. Change your delivery address or add new from <a class="hypIndiaBazaar" href="profile#address-book">';
                html+= 'here. </a> <br>In case you are looking for deliveries at your selected address please place a shipping order at <a class="hypIndiaBazaar" href="https://shop.india.bazaar.us" target="_blank">https://shop.india.bazaar.us</a>' ;
                window.$('.Vue-Toastification__toast-body').append(html);
              })
            }
          }
          this.cart_details_loading = false;
        });
    },
    getStoreByCategory(category){
      if(localStorage.getItem("is_business_model") == 3){
        return true;
      } else {
        this.category.shop_category_id = category.category_id;
        this.category.shop_category_name = category.category_name;
        this.category.type = "category";
        localStorage.setItem("selected-category", JSON.stringify(this.category));
        this.$router.push({ path: `/category?categoryid=${this.category.shop_category_id.toString()}` });
      }
    },
    priceFormatter(value) {
      if(value){
        var price = value.toString().split(".")[0];
        var decimal = value.toString().split(".")[1];
        var response = this.currency_symbol + price;
        var decimal_font_size = this.show_price_same_font;
        if(decimal != undefined){
          if(decimal_font_size == 1){
            response += "<span>." + decimal + "</span>";
          } else {
            response += "<span class='price-decimal'><small>." + decimal + "</small></span>";
          }
        } else {
          if(decimal_font_size == 1){
            response += "<span>.00</span>";
          } else{
            response += "<span class='price-decimal'><small>.00</small></span>";
          }
          
        }
        return response;
      } else {
        return this.currency_symbol + '0';
      }
    },
    getAddedCartItems(items){
      return items.filter(
        function(elem) {
          if (elem.is_added == "1") return elem;
        }
      );
    },
    getShopItemDetail() {
      this.getcatdetaildisp = [];
      this.store_detail.store_id = this.getcartdata.data!=undefined ? this.getcartdata.data.store.store_id : '';
      this.store_detail.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.store_detail.page = 1;
      if(localStorage.getItem("show_out_of_stock_items") != null && localStorage.getItem("show_out_of_stock_items") == 0){
        this.store_detail.out_of_stock = 0;
      }

      this.$store
        .dispatch("getshopsearchitemdetail", this.store_detail)
        .then(response => {
          this.getcatdetaildisp = JSON.parse(JSON.stringify(response));
        });
    },
    showDeliveryChanges(){
      this.delivery_charges = null;
      this.delivery_charge_msg = "";
      if(this.delivery_pickup == 1){
        this.show_delivery_charges = true;
        this.getDeliveryCharges();
      } else {
        this.show_delivery_charges = false;
      }
      localStorage.setItem('delivery_pickup', this.delivery_pickup);
    },
    calPriceByQty(price, qty){
      var total = (parseFloat(price) * parseFloat(qty))
      return total.toFixed(2);
    },
    getCart() {
      this.get_cart_detail.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.get_cart_detail.cart_type = 1;
      this.get_cart_detail.timezone = "+6";
      this.loading = true;
      this.$store
        .dispatch("getcartdetail", this.get_cart_detail)
        .then(response => {
          this.getcartdata = JSON.parse(JSON.stringify(response));
          bus.$emit("getcartlength", this.totalCntProductQnty);
          if(this.getcartdata.status == 1){

            if(this.getcartdata.data.order_settings.delivery != 1 && this.getcartdata.data.order_settings.pickup == 1){
              this.delivery_pickup = 2;
            }
            if(this.getcartdata.data.order_settings.delivery == 1 || this.getcartdata.data.order_settings.pickup == 1){
            this.showDeliveryChanges();
            }
          }
          this.getShopItemDetail();

          if(this.getcartdata.status == 0 && this.remove_selected_item == true){
            this.remove_selected_item = false;
            this.$router.push({ name: "home" });
          }
          this.loading = false;
          this.cart_details_loading = false;
          this.iseatry = this.getcartdata.data!=undefined ? this.getcartdata.data.store.iseatery : 0;
        });
    },
    async addToCart(store_id, item_id, item_variant_id, cart_id,variant_cart_id) {
      this.add_to_cart.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.add_to_cart.store_id = store_id;
      this.add_to_cart.item_id = item_id;
      this.add_to_cart.item_variants_id = item_variant_id;
      this.add_to_cart.quantity = this.item_qty;
      this.add_to_cart.cart_type = 1;
      if(this.iseatry == 0){
        this.add_to_cart.cart_id = variant_cart_id;
      } else {
        this.add_to_cart.cart_id = cart_id;
      }
      
      await this.$store
        .dispatch("addtocartproduct", this.add_to_cart)
        .then(response => {
          var result  = JSON.parse(JSON.stringify(response));
          if (result != "") {
            if (result.status == "0") {
              this.$toast.error(result.message);
            } else {
              this.$toast.success(result.message);
              this.getCart();
            }
          }
        });
    },
    getComboVariantChoices(choicesObject){
      var variant_choices=[];
      if(choicesObject != null && choicesObject != undefined)
      {
           if(choicesObject.combo_variants != null && choicesObject.combo_variants != undefined)
           {
              choicesObject.combo_variants.forEach((item_variant)=>{
                if(item_variant.choices != null && item_variant.choices != undefined && item_variant.choices.length > 0)
                {
                  item_variant.choices.forEach((choices)=>{
                    var choiceObj = choices.choice_prices.filter(x=>x.is_added==1);
                     if(choiceObj != undefined && choiceObj.length > 0)
                     {
                        choiceObj.forEach((choice_obj)=>{
                          var tempChoiceObj={};
                          tempChoiceObj["variant_choice_id"] = choice_obj.variant_choice_id;
                          tempChoiceObj["variant_choice_price_id"] = choice_obj.variant_choice_price_id;
                          variant_choices.push(tempChoiceObj);
                        })
                     }
                  })
                }
              })
           }
      }
      return variant_choices;
    },
    getComboVariantAddons(addonsObject){
      var variant_addons=[];
      if(addonsObject != null && addonsObject != undefined)
      {
           if(addonsObject.combo_variants != null && addonsObject.combo_variants != undefined && addonsObject.combo_variants.length > 0)
           {
              addonsObject.combo_variants.forEach((item_variant)=>{
                if(item_variant.addons != null && item_variant.addons != undefined && item_variant.addons.length > 0)
                {
                  item_variant.addons.forEach((add_ons)=>{
                    var addOnObj = add_ons.addon_prices.filter(x=>x.is_added==1);
                     if(addOnObj != undefined && addOnObj.length > 0)
                     {
                        addOnObj.forEach((choice_obj)=>{
                          var tempaddOnObj={};
                          tempaddOnObj["variant_addon_id"] = choice_obj.variant_addon_id;
                          tempaddOnObj["variant_addon_price_id"] = choice_obj.variant_addon_price_id;
                          variant_addons.push(tempaddOnObj);
                        })
                     }
                  })
                }
              })
           }
      }      
      return variant_addons;
    },
    getVariantChoices(choicesObject){
      var variant_choices=[];
      if(choicesObject != null && choicesObject != undefined)
      {
           if(choicesObject.item_variants != null && choicesObject.item_variants != undefined && choicesObject.item_variants.length > 0)
           {
              choicesObject.item_variants.forEach((item_variant)=>{
                if(item_variant.choices != null && item_variant.choices != undefined && item_variant.choices.length > 0)
                {
                  item_variant.choices.forEach((choices)=>{
                    var choiceObj = choices.choice_prices.filter(x=>x.is_added==1);
                     if(choiceObj != undefined && choiceObj.length > 0)
                     {
                        choiceObj.forEach((choice_obj)=>{
                          var tempChoiceObj={};
                          tempChoiceObj["variant_choice_id"] = choice_obj.variant_choice_id;
                          tempChoiceObj["variant_choice_price_id"] = choice_obj.variant_choice_price_id;
                          variant_choices.push(tempChoiceObj);
                        })
                     }
                  })
                }
              })
           }
      }
      return variant_choices;
    },
    getVariantAddons(addonsObject){
      var variant_addons=[];
      if(addonsObject != null && addonsObject != undefined)
      {
           if(addonsObject.item_variants != null && addonsObject.item_variants != undefined && addonsObject.item_variants.length > 0)
           {
              addonsObject.item_variants.forEach((item_variant)=>{
                if(item_variant.addons != null && item_variant.addons != undefined && item_variant.addons.length > 0)
                {
                  item_variant.addons.forEach((add_ons)=>{
                    var addOnObj = add_ons.addon_prices.filter(x=>x.is_added==1);
                     if(addOnObj != undefined && addOnObj.length > 0)
                     {
                        addOnObj.forEach((choice_obj)=>{
                          var tempaddOnObj={};
                          tempaddOnObj["variant_addon_id"] = choice_obj.variant_addon_id;
                          tempaddOnObj["variant_addon_price_id"] = choice_obj.variant_addon_price_id;
                          variant_addons.push(tempaddOnObj);
                        })
                     }
                  })
                }
              })
           }
      }      
      return variant_addons;
    },
    addQuantity(store_id, item, item_variant, index, index1, cart_id, variant_cart_id) {
      var qty = parseInt(window.$("#quantity" + item_variant.item_variants_id + '_' + index + '_' + index1).val()) + 1;

      if(item_variant.min_qty > qty && qty != 0 && item_variant.min_qty != 0){
        this.$toast.error("The minimum quantity for item is " + item_variant.min_qty);
        return false;
      }

      if(item_variant.max_qty < qty && qty != 0 && item_variant.max_qty != 0){
        this.$toast.error("The maximum quantity for item is " + item_variant.max_qty);
        return false;
      }
      
      window.$("#quantity" + item_variant.item_variants_id + '_' + index + '_' + index1).val(parseInt(qty));
      this.item_qty = parseInt(qty);

      if(item.combo_variants != null){
        this.repeat_combo = null;
        this.combo_data.item = item;
        this.combo_data.item_variant = item_variant;
        this.combo_data.index = index;
        this.combo_data.index1 = index1;
        this.combo_data.cart_id = cart_id;
        this.add_to_cart.combo_items = this.getcartdata.data.items.filter(x=>x.cart_id == cart_id);
        this.checkComboItem(index1, index, store_id, item, item_variant, cart_id, true);
        return false;
      }
      if(item.item_variants != null && (item.item_variants[index].addons != null || item.item_variants[index].choices != null)){
        this.repeat_addon_choice = null;
        this.item_variant_data.item = item;
        this.item_variant_data.item_variant = item_variant;
        this.item_variant_data.index = index;
        this.item_variant_data.index1 = index1;
        this.item_variant_data.cart_id = cart_id;
        this.getRepeatAddonChoices(store_id,item_variant.item_variants_id,item_variant.cart_id)
        return false;
      }

      this.addToCart(
        store_id,
        item.item_id,
        item_variant.item_variants_id,
        cart_id,
        variant_cart_id
      );
    },
    getRepeatAddonChoices(store_id, item_variants_id,cart_id){
      this.used_choices_addons.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.used_choices_addons.store_id = store_id;
      this.used_choices_addons.item_variants_id = item_variants_id;
      this.used_choices_addons.cart_id = cart_id;
      this.$store
        .dispatch("usedchoicesaddons", this.used_choices_addons)
        .then(response => {
          if (response.status == 1) {
            this.repeat_addon_choice = JSON.parse(JSON.stringify(response.data));
            window.$('#RepeatAlertModal').modal("show");
          } else {
            this.repeat_addon_choice = null;
          }
        });
    },
    removeQuantity(store_id, item, item_variant, index, index1, cart_id) {
      for(var i=0;i<item.length;i++)
      {
        item[i].addons = JSON.stringify(this.getComboVariantChoices(item[i]));
      }
      var qty = parseInt(window.$("#quantity" + item_variant.item_variants_id + '_' + index + '_' + index1).val()) - 1;

      if(item_variant.min_qty > qty && qty != 0 & item_variant.min_qty != 0){
        this.$toast.error("The minimum quantity for item is " + item_variant.min_qty);
        return false;
      }
      if(item_variant.max_qty < qty && qty != 0 && item_variant.max_qty != 0){
        this.$toast.error("The maximum quantity for item is " + item_variant.max_qty);
        return false;
      }

      window.$("#quantity" + item_variant.item_variants_id + '_' + index + '_' + index1).val(parseInt(qty));
      this.item_qty = parseInt(qty);
      this.add_to_cart.add_new = 0;

      if(item.combo_variants != null){
        this.repeat_combo = null;
        this.combo_data.item = item;
        this.combo_data.item_variant = item_variant;
        this.combo_data.index = index;
        this.combo_data.index1 = index1;
        this.combo_data.cart_id = cart_id;
        this.add_to_cart.combo_items = this.getcartdata.data.items.filter(x=>x.cart_id == cart_id);
        this.add_to_cart.choices = JSON.stringify(this.getComboVariantChoices(item));
        this.add_to_cart.addons = JSON.stringify(this.getComboVariantAddons(item));
      }
      else if(item.item_variants != null){
        this.repeat_addon_choice = null;
        this.item_variant_data.item = item;
        this.item_variant_data.item_variant = item_variant;
        this.item_variant_data.index = index;
        this.item_variant_data.index1 = index1;
        this.item_variant_data.cart_id = cart_id;
        this.add_to_cart.choices = JSON.stringify(this.getVariantChoices(item));
        this.add_to_cart.addons = JSON.stringify(this.getVariantAddons(item));
      }

      this.addToCart(
        store_id,
        item.item_id,
        item_variant.item_variants_id,
        cart_id
      );
    },
    removeCart() {
      this.remove_cart.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.remove_cart.cart_type = 1;
      this.$store
        .dispatch("getemptycart", this.remove_cart)
        .then(response => {
          this.$toast.success(JSON.parse(JSON.stringify(response)).message);
          this.$router.push({ name: "home" });
        });
    },
    removeSelCart(cart_id, item_variant_id, item_id, store_id) {
      this.remove_cart_item.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.remove_cart_item.store_id = store_id;
      this.remove_cart_item.item_id = item_id;
      this.remove_cart_item.item_variants_id = item_variant_id;
      this.remove_cart_item.cart_id = cart_id;
      this.loading = true;
      this.$store
        .dispatch("getremoveitemfrmcart", this.remove_cart_item)
        .then((result) => {
          this.remove_selected_item = true;
            if (result.status == "0") {
              this.$toast.error(result.message);
            } else {
              this.$toast.success(result.message);
              this.getCart();
            }
          this.loading = false;
        });
    },
    getLoginUserId(){
      return (this.userprofile != null) ? this.userprofile.user_id : "0";
    },    
    checkout(){
      window.$('.Vue-Toastification__toast-body').addClass('toaster-msg');
      if(this.getcartdata.data.order_settings.delivery == 1 && this.delivery_pickup == 1   && this.default_address_object != undefined && this.default_address_object[0].address2 == '')
      {
        this.$toast.error(validationmessage, {timeout:15000});
        return false;
      }
      if(this.delivery_charge_msg != "" && this.show_delivery_charges == true){
        var add1 = this.default_address_object[0].address;
        var add2 = this.default_address_object[0].address2;
        var combineAdd = "(" + this.space(add1, add2) + ")";
        this.$toast.error(this.spaceTwo(this.delivery_charge_msg,combineAdd), {timeout:15000});
        window.$(document).ready(function(){
          var html = '. Change your delivery address or add new from <a class="hypIndiaBazaar" href="profile#address-book">';
          html+= ' here </a>';
          window.$('.Vue-Toastification__toast-body').append(html);
        })
        
      }
      else if(this.getcartdata.data.store_status == 0)
      {
        this.$toast.error("Store is temporarily closed.", {timeout:15000});
      }
      else if(this.getcartdata.data.is_subscription_expire == 1)
      {
        this.$toast.error("Store is currently not accepting orders.", {timeout:15000});
      }
       else {
        if(this.getcartdata.data.order_settings.delivery == 1 || this.getcartdata.data.order_settings.pickup == 1)
        {
            if(this.getcartdata.data.order_settings.accept_after_close == 1 || this.isStoreOpen(this.getcartdata.data.business_hours))
            {
             this.$router.push({ name: "placeorder" });
            }
            else
            {
              this.$toast.error("This store does not accept order outside of business hours",{timeout:5000});
            }
         }
         else
         {
           this.$toast.error("This store does not accept order outside of business hours",{timeout:5000});
         }
      }
    },
    isStoreOpen(business_hours){
      if (business_hours != null && business_hours.length > 0) {
          //var timezone = (localStorage.getItem("timezone")!=null) ? localStorage.getItem("timezone") : 'Asia/Kolkata';
          var openTime = business_hours[0].from_time;
          var closeTime = business_hours[0].to_time;
          
          const now = moment.tz(this.getcartdata.data.store.timezone);
          const date = now.format("YYYY-MM-DD");
          var current_day = now.day();
          var new_current_day;

          switch (current_day) {
            case 0:
            new_current_day = 6;
            break;
            case 1:
            new_current_day = 0;
            break;
            case 2:
            new_current_day = 1;
            break;
            case 3:
            new_current_day = 2;
            break;
            case 4:
            new_current_day = 3;
            break;
            case 5:
            new_current_day = 4;
            break;
            case 6:
            new_current_day = 5;
          }

          if(new_current_day != business_hours[0].days){
            return false;
          }

          let check;
          const storeOpenTime = moment.tz(date + ' ' + openTime, "YYYY-MM-DD h:mmA", this.getcartdata.data.store.timezone);
          const storeCloseTime = moment.tz(date + ' ' + closeTime, "YYYY-MM-DD h:mmA", this.getcartdata.data.store.timezone);

          if (storeCloseTime.isBefore(storeOpenTime)) {
            check = now.isAfter(storeOpenTime) || now.isBefore(storeCloseTime);
          } else {
            check = now.isBetween(storeOpenTime, storeCloseTime, null, '[)');
          }
          return check ? true : false;
      } else {
        return false;
      }
    },
    getUnitPrice(item, combo_variants){
      var sum = 0;
      sum += parseFloat(item.discounted_price);
      if(item.choices != null) {
        item.choices.forEach(choice => {
          choice.choice_prices.forEach(choice_price => {
              if(choice_price.is_added == 1){
                sum += parseFloat(choice_price.price);
              }
          });
        });
      }

      if(item.addons != null) {
        item.addons.forEach(addon => {
          addon.addon_prices.forEach(addon_price => {
              if(addon_price.is_added == 1){
                sum += parseFloat(addon_price.price);
              }
          });
        });
      }
      if(combo_variants != null){
        combo_variants.forEach(combo_variant => {
          if(combo_variant.choices != null){
            combo_variant.choices.forEach(choice => {
              choice.choice_prices.forEach(choice_price => {
                if(choice_price.is_added == 1){
                  sum += parseFloat(choice_price.price);
                }
              });
            });
          }

          if(combo_variant.addons != null){
            combo_variant.addons.forEach(addon => {
              addon.addon_prices.forEach(addon_price => {
                if(addon_price.is_added == 1){
                  sum += parseFloat(addon_price.price);
                }
              });
            });
          }

        });
      }
      return parseFloat(sum).toFixed(2);
    },

    getUnitDiscountedPrice(item, combo_variants){
      var sum = 0;
      sum += parseFloat(item.item_price);
      if(item.choices != null) {
        item.choices.forEach(choice => {
          choice.choice_prices.forEach(choice_price => {
              if(choice_price.is_added == 1){
                sum += parseFloat(choice_price.price);
              }
          });
        });
      }

      if(item.addons != null) {
        item.addons.forEach(addon => {
          addon.addon_prices.forEach(addon_price => {
              if(addon_price.is_added == 1){
                sum += parseFloat(addon_price.price);
              }
          });
        });
      }
      if(combo_variants != null){
        combo_variants.forEach(combo_variant => {
          if(combo_variant.choices != null){
            combo_variant.choices.forEach(choice => {
              choice.choice_prices.forEach(choice_price => {
                if(choice_price.is_added == 1){
                  sum += parseFloat(choice_price.price);
                }
              });
            });
          }

          if(combo_variant.addons != null){
            combo_variant.addons.forEach(addon => {
              addon.addon_prices.forEach(addon_price => {
                if(addon_price.is_added == 1){
                  sum += parseFloat(addon_price.price);
                }
              });
            });
          }

        });
      }
      return parseFloat(sum).toFixed(2);
    },
    repeatItemAddonChoices(store_id){
      this.addon_choice_cart = true;
      this.add_to_cart.add_new = 0;
      this.add_to_cart.choices = JSON.stringify(this.getVariantChoices(this.item_variant_data.item));
      this.add_to_cart.addons = JSON.stringify(this.getVariantAddons(this.item_variant_data.item));
      this.addToCart(store_id, this.item_variant_data.item.item_id, this.item_variant_data.item_variant.item_variants_id, this.item_variant_data.cart_id);
      window.$('#RepeatAlertModal').modal("hide");
    },
    addNewItemAddonChoices(){
      this.addon_choice_cart = false;
      this.add_to_cart.add_new = 1;
      this.getItemAddonChoices(this.item_variant_data.item, 0, this.item_variant_data.item.item_name);
      window.$('#RepeatAlertModal').modal("hide");
    },
    getItemAddonChoices(item, index, item_name) {
      bus.$emit("ac_item", item);
      bus.$emit("ac_item_index", index);
      bus.$emit("ac_item_variant", item.item_variants[0]);
      bus.$emit("ac_item_name", item_name);
      window.$("#AvailableOptioncart").modal("show");
    },
    addToCartAddonChoices(data){
      this.addon_choice_cart = true;
      this.add_to_cart.add_new = 1;
      this.add_to_cart.choices = JSON.stringify(data.choices);
      this.add_to_cart.addons = JSON.stringify(data.addons);

      // Used Addon Choice Local Storage
      this.used_addon_choice.item_id = data.item.item_id;
      this.used_addon_choice.item_name = data.item.item_name;
      this.used_addon_choice.brand_name = data.item.brand_name;
      this.used_addon_choice.item_variants_id = data.item_variant.item_variants_id;
      this.used_addon_choice.item_price = (parseFloat(data.item_variant.discounted_price) + parseFloat(data.addon_choice_price));
      this.used_addon_choice.choice_details = data.choices;
      this.used_addon_choice.addon_details = data.addons;

      var local_used_addon_choice = JSON.parse(localStorage.getItem("used_addon_choice")) || [];
      if(localStorage.getItem("used_addon_choice") != null) {
        var new_used_addon_choice = this.isItemExistsUsedAddonChoices(local_used_addon_choice, this.used_addon_choice);
        localStorage.setItem('used_addon_choice', JSON.stringify(new_used_addon_choice));
      } else {
        local_used_addon_choice.push(this.used_addon_choice);
        localStorage.setItem('used_addon_choice', JSON.stringify(local_used_addon_choice));
      }
      this.addToCart(this.getcartdata.data.store.store_id,data.item.item_id,data.item_variant.item_variants_id,data.item.cart_id);
    },
    isItemExistsUsedAddonChoices(local_used_addon_choice, used_addon_choice){
      const i = local_used_addon_choice.findIndex(o => o.item_id == used_addon_choice.item_id && o.item_variants_id == used_addon_choice.item_variants_id);
      if (i > -1) {
        local_used_addon_choice[i] = used_addon_choice;
      } else { 
        local_used_addon_choice.push(used_addon_choice);
      }
      return local_used_addon_choice;
    },
    getRepeatCombo(index1, index, store_id, item_variants_id,cart_id){
      this.used_combo_data.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.used_combo_data.store_id = store_id;
      this.used_combo_data.item_variants_id = item_variants_id;
      this.used_combo_data.cart_id = cart_id;
      this.$store
        .dispatch("usedchoicesaddons", this.used_combo_data)
        .then(response => {
          if (response.status == 1) {
            this.repeat_combo = JSON.parse(JSON.stringify(response.data));
            window.$('#RepeatComboAlertModal'+index+index1).modal("show");
          } else {
            this.repeat_combo = null;
          }
        });
    },
    repeatItemCombo(store_id){
      this.add_to_cart.add_new = 0;
      this.addToCart(store_id, this.combo_data.item.item_id, this.combo_data.item_variant.item_variants_id, this.combo_data.cart_id);
      window.$('#RepeatComboAlertModal'+this.combo_data.index+this.combo_data.index1).modal("hide");
    },
    addNewItemCombo(store_id){
      this.add_to_cart.add_new = 1;
      window.$('#RepeatComboAlertModal'+this.combo_data.index+this.combo_data.index1).modal("hide");
      this.getItemCombo(store_id, this.combo_data.item, this.combo_data.item_variant, this.combo_data.index, this.combo_data.index1, this.combo_data.cart_id);
    },
    getItemCombo(store_id, item, item_variant, index, index1, cart_id) {
      bus.$emit("comb_item", item);
      bus.$emit("comb_item_index", index);
      bus.$emit("comb_item_index1", index1);
      bus.$emit("comb_item_variant", item_variant);
      bus.$emit("comb_item_name", item.item_name);
      bus.$emit("comb_cart_id", cart_id);
      window.$("#comboOffercart").modal("show");
    },
    checkComboItem(index1, index, store_id, item, item_variant, cart_id, is_repeat) {
       this.combo_item_data.item_id = item.item_id;
       this.combo_item_data.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
       this.$store
         .dispatch("getproductdetail", this.combo_item_data)
         .then(response => {
           this.combo_loading = false;
           var result = JSON.parse(JSON.stringify(response));
           if(result.status == 1 && result.data.combo_items != null){
             this.combo_item_details = result.data.combo_items;
             var vm = this;
             this.selectedComboItems = [];
             var has_addon_choices = false;
             this.combo_item_details.forEach(function(combo_items) {
              var filter_combo_items = combo_items.combo_variants.filter(sub => sub.addons != null || sub.choices != null);
              if(!has_addon_choices){
                has_addon_choices = (filter_combo_items.length > 0) ? true : false;
              }
              
              combo_items.combo_variants.forEach(function(citem) {
                var obj = {};
                obj['combo_item_id'] = combo_items.combo_item_id;
                obj['item_name'] = citem.item_name;
                obj['combo_item_detail_id'] = citem.combo_item_detail_id;
                obj['quantity'] = 1;
                vm.selectedComboItems.push(obj);
              });
             });
             result.data.combo_items.forEach(comboVariantObj => {
               if(comboVariantObj.combo_variants.length > 1)
               {
                 has_addon_choices = true;
               }
             })
             if(!has_addon_choices) {
               var selected_data = {};
               selected_data['item'] = item;
               selected_data['index'] = index;
               selected_data['item_variant'] = item_variant;
               selected_data['combo_items'] = this.selectedComboItems;
               selected_data['cart_id'] = cart_id;
               this.repeatItemCombo(store_id);
             } else {
              if(is_repeat){
                this.getRepeatCombo(index1, index, store_id, item_variant.item_variants_id,item_variant.cart_id);
              } else {
                window.$("#comboOffercart").modal("show");
              }
             }
           }
         });
    },
    addToCartCombo(data){
      this.add_to_cart.add_new = 1;
      this.add_to_cart.combo_items = JSON.stringify(data.combo_items);
      this.addToCart(this.getcartdata.data.store.store_id, data.item.item_id, data.item_variant.item_variants_id, data.cart_id);
    },
    filterComboAddons(addons){
      var tmp_addons = [];
      addons.forEach(addon => {
        addon.addon_prices.forEach(addon_price => {
          if(addon_price.is_added == 1){
            var obj = {};
            obj['variant_addon_name'] = addon.variant_addon_name;
            obj['variant_sub_addon_name'] = addon_price.variant_sub_addon_name;
            tmp_addons.push(obj);
          }
        });
      });
     return tmp_addons;
    },
    filterComboChoices(choices){
      var tmp_choices = [];
      choices.forEach(choice => {
        choice.choice_prices.forEach(choice_price => {
          if(choice_price.is_added == 1){
            var obj = {};
            obj['variant_choice_name'] = choice.variant_choice_name;
            obj['variant_sub_choice_name'] = choice_price.variant_sub_choice_name;
            tmp_choices.push(obj);
          }
        });
      });
     return tmp_choices;
    }
  }
};
</script>